import { Component } from '@angular/core';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-dtt-logo',
  standalone: true,
  imports: [ImageModule],
  styleUrl: './dtt-logo.component.scss',
  templateUrl: './dtt-logo.component.html',
})
export class DttLogoComponent {
  dttLogo = '../../../../assets/images/DTT_logo.png';

  constructor() {}
}
